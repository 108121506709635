import Test from './Test/Test';

function App() {
  return (
    <div>
      <h1>Hello world. From George</h1>
      <Test />
    </div>
  );
}

export default App;
